

@import url(https://fonts.googleapis.com/css?family=Lato);

@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/bootstrap-material-design/sass/bootstrap-material-design";
@import "node_modules/bootstrap-material-design/sass/ripples";


body{
    font-family: 'Lato', sans-serif;
        background-color: #EEEEEE;
}

#loginpage{
    padding-top: 10%;
}